<template>
  <div>
    <headers />
    <div class="head">
      <img
        src="@/assets/images/toolBj.png"
      />
      <div class="headTit">软件及工具</div>
      <div class="searchBox">
        <search @search="searchFun" :type="2" />
      </div>
    </div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
        <el-breadcrumb-item>资料获取</el-breadcrumb-item>
        <el-breadcrumb-item>软件及工具</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <div class="leftBox">
        <div class="menuBox" v-for="(item, index) in menuList" :key="index">
          <div class="menuItem" @click="actMenu(index)">
            <div class="menuItemL" :style="act == index ? 'color:#00D8C3' : ''">
              {{ item.name }}
            </div>
            <div class="menuItemR"></div>
          </div>
        </div>
      </div>
      <div class="rightBox">
        <template v-if="list.length">
        <div
          class="card"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="title">
            {{item.name}}
          </div>
          <div class="title2">
            <!-- <div>关于产品的描述文字，点击查看资料即可进入产品详情，</div>
            <div>进入下载即可查看产品PDF</div> -->
            {{item.softwareDesc}}
          </div>

          <el-button @click="toToolDetails(item)" class="lookDetails">查看详情</el-button>
        </div>

        <div class="page">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="pages.total"
            :page-size="pages.size"
            :current-page="pages.page"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
        </template>
        <None v-else />
      </div>
    </div>

    <footers />
  </div>
</template>

<script>
import headers from "@/components/header.vue";
import footers from "@/components/footer.vue";
import search from "@/components/search.vue";

import { softwareClassifyList, softwareList, softwareView } from "@/api/lx";

export default {
  name: "tool",
  components: {
    headers,
    footers,
    search,
  },
  data() {
    return {
      act: 0,
      menuList: [],
      searchValue:'',
      pages: {
        page: 1,
        total: 0,
        size: 10,
      },
      list: [],
    };
  },
  created() {
    this.getSoftwareClassifyList();
  },
  mounted() {},
  methods: {
    currentChange(val) {
      this.pages.page = val;
      this.getSoftwareList();
    },
    searchFun(val) {
      this.searchValue = val;
      this.pages.page = 1;
      this.getSoftwareList();
    },
    getSoftwareClassifyList() {
      softwareClassifyList({}).then((res) => {
        this.menuList = res.data;
        this.getSoftwareList();
      });
    },
    getSoftwareList() {
      softwareList({
        page: this.pages.page,
        size: this.pages.size,
        name: this.searchValue,
        classifyId: this.menuList[this.act].id,
      }).then((res) => {
        this.list = res.data.records;
        this.pages.total = res.data.total;
      });
    },
    actMenu(val) {
      this.act = val;
      this.pages.page = 1;
      this.getSoftwareList()
    },
    toToolDetails(item) {
      console.log('1312')
      this.$router.push({
        path: "/information/toolDetails",
        query: { id: item.id },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  width: 100%;
  height: 300px;
  position: relative;
  overflow: hidden;
  .headTit {
    width: 100%;
    text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 42px;
    color: #ffffff;
    position: relative;
    z-index: 1;
    margin-top: 80px;
  }
  .searchBox {
    margin-top: 54px;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.content {
  width: 100%;
  display: flex;
}
.leftBox {
  width: 477px;
  .menuBox {
    width: 393px;
    margin-left: 45px;
    padding: 15px 30px 15px 0px;
    box-sizing: border-box;
    border-top: 1px solid #e6edf0;
    cursor: pointer;
    .menuItem {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      font-size: 20px;
      color: #00161a;
    }
    .menuCellBox {
      border-top: 1px solid #e6edf0;
      padding-top: 15px;
      box-sizing: border-box;
      margin-top: 15px;
      :first-child {
        margin-top: 0 !important;
      }
      .menuCellItem {
        font-size: 16px;
        color: #00161a;
        line-height: 22px;
        margin-top: 15px;
      }
    }
  }
}
.rightBox {
  width: calc(100vw - 477px);
  margin-top: 15px;
}
.searchBox {
  margin-bottom: 20px;
}
.card {
  width: 1398px;
  border: 1px solid #e6edf0;
  border-top: 5px solid #00d8c3;
  overflow: hidden;
  padding: 20px 30px 30px 30px;
  box-sizing: border-box;
  background-color: #f9f9f9;
  margin-bottom: 30px;
  .title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 18px;
    color: #00161a;
    line-height: 28px;
    text-align: left;
    font-style: normal;
  }
  .title2 {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #525f66;
    line-height: 22px;
    margin-top: 15px;
  }
  .lookDetails {
    width: 104px;
    height: 39px;
    margin-top: 29px;
    background: #00d8c3;
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
    &.is-active,
    &:active {
      border: 1px solid #00d8c3; /* 更改为你想要的颜色 */
      outline: none; /* 移除默认的轮廓线 */
    }
  }
}
.page {
  margin-left: 233px;
  margin-bottom: 55px;
}
</style>